<template>

<div class="save" v-if="selection">

	<div class="save-text">{{ content.save.title }}</div>

	<input class="save-input" v-if="!success" v-model="email" type="text" :placeholder="content.save.placeholder" :disabled="loading" v-on:keypress.enter="onSubmit" />

	<div class="save-link" v-if="success">

		<span>Email sent! You can also bookmark the link below;</span>
		<a :href="success">{{ success }}</a>

	</div>

	<div class="save-button" :class="{'is-disabled': !valid, 'is-loading': loading, 'is-success': success}" v-on:click="onSubmit" />
	
</div>

</template>

<script>

export default {

	data: function() {

		return {
			email: '',
			loading: false,
			success: false
		}

	},

	computed: {

		valid: function() {

			return this.email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

		},

		selection: function() {

			return this.$store.getters['selection']

		},

		content: function() {

			return this.$store.getters['data'].recommendations

		}

	},

	methods: {

		onSubmit: function() {

			if (this.valid && !this.loading) {

				this.loading = true
				
				this.$store.dispatch('save', this.email).then(function(data) {

					this.success = data.key

				}.bind(this))

			}

		}

	}

}

</script>

<style scoped>

.save {
	margin-top: 80px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.is-mobile .save {
	margin-top: 20px;
	flex-direction: column;
}

.save-text {
	color: #2A273E;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	text-transform: uppercase;
	width: 200px;
}

.is-mobile .save-text {
	width: 100%;
	text-align: center;
	font-size: 14px;
	font-weight: 300;
	color: #707070;
	letter-spacing: 2px;
}

.save-link {
	background-color: #58C145;
	height: 64px;
	width: 536px;
	border-radius: 32px;
	padding: 0px 40px;
	font-size: 18px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	flex-direction: column;
}

.save-link span {
	font-size: 14px;
	font-weight: bold;
}

.save-link a {
	font-size: 18px;
	margin-top: 4px;
	border-bottom: 1px dashed #fff;
}

.save-link a:hover {
	border-bottom: 1px solid #fff;
}

.save-input {
	background-color: #F0F0F0;
	height: 64px;
	width: 536px;
	border-radius: 32px;
	text-transform: uppercase;
	padding: 0px 40px;
	font-size: 18px;
	color: #2A273E;
	line-height: 22px;
}

.is-mobile .save-input {
	width: 100%;
	text-align: center;
	border-radius: 0px;
	height: 48px;
	font-size: 14px;
	padding: 0px 50px 0px 50px;
	margin: 10px 0px;
}

.save-button {
	width: 64px;
	height: 64px;
	border-radius: 50%;
	margin-left: 10px;
	background-color: #312D50;
	cursor: pointer;
	background-image: url(~@/assets/send.svg);
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.is-mobile .save-button {
	position: absolute;
	right: 4px;
	bottom: 14px;
	width: 40px;
	height: 40px;
	background-size: 24px 24px;
}

.save-button.is-disabled {
	opacity: 0.2;
	pointer-events: none;
}

.save-button.is-loading {
	pointer-events: none;
	background-image: url(~@/assets/load.gif);
}

.is-mobile .save-button.is-loading {
	background-size: auto auto;
}

.save-button.is-success {
	pointer-events: none;
	color: #fff;
	font-size: 48px;
	line-height: 64px;
	text-align: center;
	background-color: #58C145;
	background-image: none;
}

.is-mobile .save-button.is-success {
	font-size: 32px;
	line-height: 40px;
}

.save-button.is-success:before {
	content: '\2713';
}

</style>
